@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --primary: #08b7e2;
}

::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 100px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  font-family: Poppins;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

input {
  outline: none;
}

button {
  cursor: pointer;
}

/* ===================================== common css start =============================================== */

.container {
  background: #060e24;
  width: "100%";
  height: 100%;
  flex-direction: row;
  overflow: hidden;
}

.Home .bg {
  background-image: url("./assets/images/listbox-option.png");
  background-color: #1d1d1d;
  min-height: 520px;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.Home .logo {
  width: 200px;
  height: 35px;
}

.Home .head-admin {
  padding: 20px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Home .down {
  width: 15px;
  height: 8px;
  margin-left: 20px;
}

.Home .leg-text {
  font-size: 14px;
  font-weight: 400;
}

.Home .language {
  background-color: #ffffff1a;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 140px;
  height: 32px;
}

.Home .container-header {
  flex-direction: row;
  align-items: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.Home .team-bg {
  width: 100%;
  height: 400px;
  min-width: 400px;
  transform: translate(0, 45px);
}

.Home .dow-app {
  margin-left: 20px;
}

.Home .dow-app h2 {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.Home .dow-app h3 {
  font-size: 25px;
  font-weight: 400;
  color: #d9d9d9;
  margin-top: 10px;
}

.Home .dow-app h4 {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.Home .dow-app span {
  font-size: 14px;
  font-weight: 700;
  color: #ffad14;
  margin-left: 4px;
}

.Home .input {
  width: 250px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #7c7c7c;
  padding: 0px 20px 0px 50px;
  color: #000;
  border-radius: 20px;

  font-size: 15px;
  font-weight: 400;
}

.Home .input::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.Home .mobail-num {
  margin-top: 30px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.Home .num {
  font-size: 13px;
  font-weight: 400;
  border-right-width: 1px;
  border-right-color: #7c7c7c;
  border-right-style: solid;
  position: absolute;
  top: 13px;
  left: 10px;
  color: #000;
  padding-right: 7px;
}

.Home .mobail-num button {
  border: 1px solid #7c7c7c;
  padding: 10px 40px;
  background: radial-gradient(50% 50% at 50% 50%, #1c7134 0%, #139538 100%),
    linear-gradient(0deg, #7c7c7c, #7c7c7c);

  font-size: 13px;
  font-weight: 700;
  border-radius: 20px;
  margin: 5px;
}

.Home .mobail-num button:hover {
  background: linear-gradient(180deg, #060e24 0%, #1a3273 100%);
  border: 1px solid #fff;
}

.Home .startPlay {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-direction: column;
  display: flex;
}

.Home .playing {
  font-size: 20px;
  padding: 0px 20px;
  text-align: center;
}

.Home .playing span {
  font-size: 20px;
  font-weight: 700;
  color: #08b7e2;
}

.Home .fantasy {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(233, 243, 255, 0) 0.5%,
    rgba(233, 243, 255, 0.2) 16%,
    rgba(233, 243, 255, 0.2) 83.5%,
    rgba(233, 243, 255, 0) 99.5%
  );
  width: 324px;
  height: 47px;
  margin-top: 20px;
}

.Home .fantasy h4 {
  font-size: 16px;
  font-weight: 500;
  color: #2072e4;
}

.Home .fantasy h5 {
  font-size: 16px;
  font-weight: 400;
}

.Home .left {
  width: 6px;
  height: 10px;
  margin: 0px 30px;
}

.Home .text {
  font-size: 13px;
  font-weight: 400;
  max-width: 840px;
  text-align: center;
  margin-top: 30px;
  padding: 0px 10px;
}

.Home .number {
  background: linear-gradient(180deg, #fecf15 0%, #dab10d 46.5%, #987b04 100%);
  border: 1.5px solid #ffffff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  color: #000;
  border-radius: 100%;
  padding: 10px 15px;
}

.Home .img1 {
  width: 143px;
  height: 56px;
  border-radius: 5px;
  margin: 0px 20px 0px 40px;
}

.Home .cash-out {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-bottom: -30px;
}

.Home .cashOut {
  width: 150px;
  height: 320px;
  margin-right: -15px;
  z-index: 99;
}

.Home .upcoming-mache {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
}

.Home .team-stakes {
  background-color: #122940;
  width: 220px;
  height: 113px;
  border-radius: 5px;
  padding: 30px 20px;
}

.Home .team-title {
  font-size: 9px;
  font-weight: 400;
  margin-top: 10px;
}

.Home .team-text {
  font-size: 7px;
  font-weight: 400;
  margin: 5px 0px;
}

.Home .box-content {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.Home .win-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  background: linear-gradient(
    270deg,
    rgba(8, 183, 226, 0.1) 0%,
    rgba(8, 112, 226, 0.1) 100%
  );
  width: 100%;
  min-height: 570px;
}

.Home .win {
  font-size: 20px;
  margin: 60px 0px;
}

.Home .win span {
  font-size: 20px;
  font-weight: 700;
  color: #08b7e2;
}

.Home .plyer-img {
  width: 300px;
  height: 350px;
}

.Home .contest {
  display: flex;
  align-items: center;
  margin: 20px 0px;
}

.Home .game-icon {
  width: 52px;
  height: 52px;
}

.Home .win-text {
  font-size: 16px;
  font-weight: 400;
  margin-left: 15px;
}

.Home .win-text span {
  color: #08b7e2;
  /* font-family: Roboto; */
  font-size: 16px;
  font-weight: 400;
  margin-left: 5px;
}

.Home .qr-text {
  font-size: 16px;
  font-weight: 400;
  margin-left: 15px;
  text-align: center;
  width: 260px;
}

.Home .down-app {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.Home .down-type {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.Home .app-icon {
  width: 150px;
  height: 50px;
}

.Home .qr-icon {
  width: 90px;
  height: 90px;
  margin: 0px 20px;
}

.Home .down-scan {
  max-width: 560px;
  margin: 30px 30px;
}

.Home .testimonials {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.Home .testimonials h3 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 50px;
  display: inline-block;
}

.Home .user-box {
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  float: left;
  scroll-behavior: smooth;
}

.Home .win-box {
  margin: 40px 20px;
  background-color: #102439;
  border-radius: 20px;
}

.Home .user-win {
  width: 392px;
  height: 317px;
}

.Home .user {
  display: flex;
  align-items: center;
  margin: 15px 20px;
}

.Home .user-icon {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  padding: 5px;
}

.Home .abc {
  border-radius: 100%;
  background-color: #fff;
  border: 3px solid #7c7c7c99;
}

.Home .user-name {
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
}

.Home .rupees-win {
  background: linear-gradient(270deg, #08b7e2 0%, #0870e2 100%);
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
}

.Home .mega-win {
  font-size: 15px;
  font-weight: 400;
  width: 180px;
  line-height: 20px;
}

.Home .mega-win span {
  font-size: 12px;
  font-weight: 400;
  margin-left: 6px;
}

.Home .rupee {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
  width: 120px;
}

.Home .rupee span {
  font-size: 12px;
  font-weight: 400;
}

.Home .user-win p {
  font-size: 13px;
  font-weight: 400;
  padding: 10px 20px;
}

.Home .app-safe {
  background: linear-gradient(
    270deg,
    rgba(8, 183, 226, 0.1) 0%,
    rgba(8, 112, 226, 0.1) 100%
  );
  width: 100%;
  min-height: 743px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}

.Home .app-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  margin-top: 100px;
  text-align: center;
  padding: 0px 10px;
}

.Home .app-title span {
  font-size: 20px;
  font-weight: 700;
  color: #0984e3;
  margin-left: 5px;
}

.Home .app-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  text-align: center;
  padding: 0px 10px;
  margin-bottom: 30px;
}

.Home .app-text span {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  color: #0984e3;
}

.Home .select-box {
  width: 296px;
  height: 140px;
  background-color: #102439;
  border: 1px solid #ffffff80;
  box-shadow: 0px 13px 20px 0px #0000000d;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 15px;
}

.Home .box-num {
  background: linear-gradient(270deg, #08b7e2 0%, #0870e2 100%);
  font-size: 16px;
  font-weight: 400;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: -25px;
}

.Home .box-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.Home .box-con {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.Home .follow-text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.Home .follow {
  background: linear-gradient(
    90deg,
    rgba(233, 243, 255, 0) 0.5%,
    rgba(233, 243, 255, 0.2) 16%,
    rgba(233, 243, 255, 0.2) 83.5%,
    rgba(233, 243, 255, 0) 99.5%
  );
  margin-top: 60px;
  height: 60px;
  max-width: 714px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  padding: 0px 10px;
}

.Home .follow-text span {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  color: #ffad14;
}

.Home .operates {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  margin-top: 80px;
}

.Home .limit-img {
  width: 156px;
  height: 156px;
}

.Home .operate-app {
  margin: 20px 40px;
}

.Home .operates h2 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;
}

.Home .operates p {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  width: 257px;
}

.Home .do-dont {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.Home .dont-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  max-width: 1213px;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
  padding: 0px 20px;
}

.Home .do {
  border: 1px solid #d4f0da;
  box-shadow: 0px 13px 20px 0px #0000000d;
  width: 400px;
  height: 291px;
  border-radius: 20px;
  margin: 20px 40px;
}

.Home .dont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Home .dont-icon {
  width: 291px;
  height: 47px;
  margin: -13px 0px 0px -30px;
}

.Home .do-text {
  padding: 0px 25px;
}

.Home .fantasy-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  margin: 15px 0px;
}

.Home .quit {
  background-color: #102439;
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
  height: 310px;
  width: 350px;
}

.Home .quit-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  margin-top: 100px;
  text-align: center;
  margin-bottom: 30px;
}

.Home .quit-title span {
  font-size: 20px;
  font-weight: 700;
  color: #0984e3;
  margin-left: 5px;
}

.Home .quit h2 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
}

.Home .quit h1 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}

.Home .quit-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.Home .quit p {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  margin-top: 15px;
}

.Home .cricket-tips {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Home .tips-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  margin-top: 70px;
  margin-bottom: 10px;
}

.Home .tips-title span {
  font-size: 20px;
  font-weight: 700;
  margin-left: 5px;
  color: #08b7e2;
}

.Home .cricket-tips h4 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 20px;
  text-align: center;
}

.Home .tips {
  display: flex;
  max-width: 560px;
  margin: 20px 15px;
}

.Home .tips-icon {
  width: 27px;
  height: 25px;
}

.Home .tips-text {
  font-size: 12px;
  font-weight: 400;
  margin: 0px 15px;
  max-width: 530px;
}

.Home .tips-text span {
  font-size: 12px;
  font-weight: 400;
  color: #0000ee;
  border-bottom: 1px solid #0000ee;
}

.footer_top {
  background: linear-gradient(180deg, #060e24 0%, #1a3273 100%);
  margin-top: 100px;
  width: 100%;
  min-height: 468px;
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.footer_container {
  max-width: 1000px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ffffff80;
  padding-bottom: 29px;
  align-items: center;
}

.Home .footer_logo {
  width: 250px;
  height: 40px;
}

.footer_social {
  margin: 0px 40px;
}

.footer_social a {
  display: inline-block;
  list-style: none;
  width: 32px;
  margin: 5px 3px;
}

.right_col1 {
  display: flex;
  width: 400px;
  justify-content: space-between;
  border-left: 1px solid #ffffff80;
  padding-left: 50px;
}

.left_link_list ul,
.right_link_list ul {
  padding-left: 0px;
  list-style: none;
  margin-top: 0px;
  margin-bottom: 0px;
  display: inline-block;
}

.left_link_list ul li a,
.right_link_list ul li a {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 20px;
  display: inline-block;
}

.mb44 {
  margin-bottom: 30px;
}

.membership {
  text-align: center;
}

.membership a {
  color: #fff;
  margin-bottom: 14px;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
}

.office_address {
  padding-top: 16px;
  text-align: center;
}

.address {
  font-size: 12px;
  font-weight: 400;
  /* margin: 0px 15px; */
}

.footer_bottom {
  background-color: #000;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: -70px;
}

.insta-icon {
  width: 30px;
  height: 30px;
}

.Home .member-icon {
  width: 38px;
  height: 45px;
}

.Home .left_col1 {
  margin-left: 100px;
}

@media (max-width: 1260px) {
  .dow-app {
    margin-top: 60px;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0 !important;
    padding: 0 10px;
  }

  .dow-app button {
    margin-left: 0 !important;
  }

  .qr-text {
    margin-top: 20px;
  }

  .follow {
    margin-top: 20px !important;
    margin-bottom: 50px;
  }

  .app-title,
  .tips-title,
  .quit-title,
  .startPlay,
  .win,
  .operates {
    margin-top: 30px !important;
  }

  .team-bg {
    width: 200px !important;
    height: 250px !important;
  }

  .do {
    width: 100% !important;
    height: 250px !important;
    margin: 20px !important;
  }

  .dont-icon {
    width: 200px !important;
    height: 30px !important;
    margin-left: 5px !important;
    margin-top: -6px !important;
  }

  .do-text {
    margin-top: -10px !important;
  }

  .dont-text {
    margin-bottom: 20px !important;
  }

  .plyer-img {
    width: 250px !important;
    height: 290px !important;
  }

  .language {
    width: 100px !important;
    font-size: 12px;
  }

  .logo {
    width: 140px !important;
    height: 25px !important;
  }

  .upcoming-mache {
    margin-top: -30px !important;
  }

  .text {
    margin-bottom: 50px;
  }

  .user-win {
    width: 330px !important;
    height: 330px !important;
  }

  .left_col1 {
    border-bottom: 1px solid #ffffff80;
    padding-bottom: 23px;
    margin-bottom: 13px;
    text-align: center;
    width: 100%;
    margin-left: 0px !important;
  }

  .right_col1 {
    text-align: center;
    padding-bottom: 13px;
    border-left: none;
  }

  .member-section {
    justify-content: center;
    flex-direction: row;
    display: flex;
    width: 100%;
  }

  .membership {
    margin: 20px 20px;
  }

  .footer_bottom {
    margin-top: 0px;
  }

  .right_col1 {
    padding: 0px 20px;
  }

  .footer_container {
    padding-bottom: 0px;
  }
}

.telegram {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tel-logo {
  width: calc(100% - 50px);
  border-radius: 20px;
  max-width: 350px;
}

.channel {
  padding: 10px 20px;
  background: #0870e2;
  margin-top: 30px;
  border-radius: 7px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
}

.channel img {
  width: 25px;
  margin-right: 7px;
}
